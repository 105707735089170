import styled from "styled-components/macro";
import { Box } from "~styled";

const Service = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

Service.Box = styled(Box)`
  @media (max-width: 2000px) {
    height: 78vh;
  }
  @media (max-width: 1200px) {
    height: 100vh;
  }
  @media (max-width: 768px) {
    height: 180vh;
  }
  @media (max-width: 480px) {
    height: 160vh;
  }
  overflow: hidden;
`;

export default Service;
