import React from "react";
import ServicesCard from "./Components/Card";
import Service from "./style";
import SectionTitle from "./Components/SectionTitle";
import { Col, Container, Row } from "react-bootstrap";
export default function ServicesSection() {
  return (
    <Service.Box>
      <Service>
        <Container className="container">
          <Row className="row justify-content-center">
            <Col className="col-xl-8 text-center">
                <SectionTitle
                  subTitle="Funcionalidades de empeños"
                  title="Control de empeños y compras"
                />
            </Col>
          </Row>
          <Row className="row justify-content-center">
            {/*/ .Single Services */}

            <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
                <ServicesCard
                  title="Agrega tus empeños"
                  icon="fa fa-plus-circle"
                  iconColor="#099a97"
                  text="Esta funcionalidad permite a los usuarios ingresar nuevos empeños en el sistema. "
                />
            </Col>
            {/* Single Services */}
            <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
                <ServicesCard
                  title="Barra de Búsqueda"
                  icon="fa fa-search"
                  iconColor="#ff7272"
                  text="La barra de búsqueda facilita la localización rápida de empeños específicos."
                />
            </Col>
            {/* Single Services */}
            <Col className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
                <ServicesCard
                  title="Detalles del Empeño"
                  icon="fa  fa-info-circle"
                  iconColor="#ff8d52"
                  text="Esta sección proporciona información detallada sobre cada empeño individual"
                />
            </Col>
            {/*/ .Single Services */}
            {/*/ .Single Services */}
            {/*/ .Single Services */}
            <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
                <ServicesCard
                  title="Desempeñar"
                  icon="fa fa-refresh"
                  iconColor="#be79df"
                  text="Esta funcionalidad permite marcar un artículo como desempeñado"
                />
            </Col>
            {/*/ .Single Services */}
          </Row>
        </Container>
      </Service>
    </Service.Box>
  );
}
